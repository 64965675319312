<template>
  <div>
  </div>
</template>

<script>
import { BCard, BCardText, BLink } from 'bootstrap-vue'
import {getAuth, signInAnonymously} from 'firebase/auth'
import {getMessaging, getToken, onMessage} from 'firebase/messaging'
import {messaging} from "@/plugins/firebase";
import  {mapGetters,mapActions} from "vuex";

export default {
  components: {
    BCard,
    BCardText,
    BLink,
  },

  async created() {
  },
  methods:{
    ...mapActions('auth',['createTopic']),
    async authenticate(topic){
      await signInAnonymously(getAuth())
      await this.activated(topic)
    },
  },
  computed:{
    ...mapGetters('auth',['getUser','getToken'])
  }
}
</script>

<style>

</style>
